// CONSTANTS
$black: rgba(7, 13, 28, 1);
$white: rgb(248, 248, 248);

// FONTS
$font-size: 20px;
$font-weight: 600;
$bold: $font-weight * 2;

$theme-colors: (
  'primary': #3b5998,
  'secondary': #9ba9c5,
  'light': #5e94ac,
  'dark': #213358,
  'info': #5d81cfe1,
  'success': #a7cc79,
  'danger': #cc6b6e,
  'warning': #ebca6f,
  'black': $black,
  'white': $white,
  'body': $black,
);

$font-primary: 'Heebo', 'sans-serif';
$font-primary-url: 'https://fonts.googleapis.com/css?family=Heebo:100';
@import url($font-primary-url);

$font-secondary: 'Heebo', 'sans-serif';
$font-secondary-url: 'https://fonts.googleapis.com/css?family=Heebo:100';
@import url($font-secondary-url);

@font-face {
  font-family: $font-primary;
  src: url($font-primary-url);
}

#outage-form textarea:enabled {
  background-color: white;
  padding: 0;
}

/* Enhance the form container */
#outage-form .container {
  background-color: #fff; /* Consider a subtle background color if appropriate */
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  max-width: 800px; /* Adjust based on your layout */
  margin: auto;
}

/* Improve form elements */
#outage-form select,
#outage-form input {
  border: 1px solid #ccc;
  border-radius: 4px;
  margin: 10px 0;
  padding: 10px;
  width: 100%;
  height: 20px;
}
#outage-form textarea {
  border: 1px solid #ccc;
  border-radius: 4px;
  margin: 10px 0;
  padding: 10px;
  width: 100%;
  height: 50px;
}

#outage-form label {
  font-weight: bold;
}

/* Adjustments for specific elements */
.production-total,
.production-target {
  padding-top: 1rem; /* Adjusted padding */
  margin-bottom: 0%; /* Added margin for separation */
}

.form-control {
  font-family: sans-serif;
}

.question-div {
  padding-left: 1vw;
  padding-right: 1vw;
  padding-top: 1vh;
  padding-bottom: 1vh;
  font-weight: $font-weight;
}
.question-div .production-total {
  padding-left: 1vw;
  padding-right: 1vw;
  padding-top: 1vh;
  padding-bottom: 0vh;
  font-weight: $font-weight;
}
.question-div .production-target {
  padding-left: 1vw;
  padding-right: 1vw;
  padding-top: 0vh;
  padding-bottom: 1vh;
  font-weight: $font-weight;
}
.question-select {
  // background-color: aqua;
  padding-left: 0vw;
  padding-right: 0.5vw;
  border-radius: 10px;
  width: fit-content;
}

.question-select.select-extended {
  min-width: 400px;
}

.question-select-option {
  // background-color: map-get($map: $theme-colors, $key: 'info');
  color: map-get($map: $theme-colors, $key: 'dark');
}
.kaes-outage-timestamp {
  padding-left: 0px;
}
.parent-element {
  display: flex;
  flex-direction: row;
  background-color: gray;
  justify-content: space-evenly;
}
.child-element {
  background-color: green;
  padding: 20px;
  margin: 10px;
  border-style: solid;
  width: 25vw;
}

// .Toastify__toast-container {
//   position: relative;
//   // padding-left: 0vw;
// }

// .temp-option {
//   background-color: aqua;
//   // border-radius: 10px;
// }
// ------------------

// .test-question {
//   background-color: aqua;
// }
// .kaes-site {
//   background-color: red;
// }
// .question1 {
//   background-color: navy;
// }
// .question2 {
//   background-color: palevioletred;
// }
// .question3 {
//   background-color: green;
// }

// .questionOp1 {
//   background-color: aqua;
// }
// .questionOp2 {
//   background-color: blueviolet;
// }

// .temp-custom {
//   font-size: xx-large;
//   font-weight: bolder;
// }

// .question1-old {
//   background-color: #cc6b6e;
// }
// .question2-old {
//   background-color: #213358;
// }
// .question3-old {
//   background-color: #a7cc79;
// }
// .select-old {
//   background-color: aqua;
// }

// Basic Styling (DO NOT MODIFY)
body {
  background-color: map-get($map: $theme-colors, $key: 'white') !important;
  color: map-get($map: $theme-colors, $key: 'black') !important;
  font-family: $font-primary !important;
  font-size: $font-size !important;

  .font-primary {
    font-family: $font-primary !important;
  }

  .font-secondary {
    font-family: $font-secondary !important;
  }

  #ReactApp {
    min-height: 100vh;
  }
  #main {
    min-height: 100vh;
  }
}

.pointer {
  cursor: pointer;
}

@import '~bootstrap/scss/bootstrap.scss';
